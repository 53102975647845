import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Accordion from '../../../components/Accordion.js'
import Layout from '../../../components/layout'

const TamilAll = ({data}) => {
    return <Layout>
        <Helmet>
            <title>Tamil Vadhyar in Bangalore (15+yrs Exp): Book Tamil Priest in Bangalore, Tamil Vadhyars in Bangalore, Iyer Vadhyar in Bangalore, Tamil Iyengar Vadhyar in Bangalore, Tamil Iyer for Housewarming in Bangalore, Tamil Brahmin Priests in Bangalore & Tamil Pandits in Bangalore</title>
            <meta name="description" content="Looking for Tamil Vadhyar in Bangalore, India? We provide the best Tamil Vadhyars in Bangalore, Tamil Priest in Bangalore, Iyer Vadhyar in Bangalore, Tamil Iyengar Vadhyar in Bangalore, Tamil Iyer for Housewarming in Bangalore, Tamil Brahmin Priests in Bangalore & Tamil Pandits in Bangalore for Marriage, Grihapravesham and others pujas." />
            <script type="application/ld+json">{`
                 {
                  "@context":"https://schema.org",
                  "@type":"FAQPage",
                  "mainEntity": [
                    {
                      "@type":"Question",
                      "name":"Should the customer arrange for Puja Samagri or would the Panditji arrange?",
                      "acceptedAnswer": 
                      {
                        "@type":"Answer",
                        "text":"Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja"
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Will the Panditji help the customer in choosing good muhurth for ritual?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"Would the Puja be performed as per the Tamil traditions?",
                      "acceptedAnswer":
                      {  
                        "@type":"Answer",
                        "text":"Namaste God provides a wide variety of qualified and experienced panditjis who are hailing from Tamilnadu. Our team would connect you with the appropriate Panditji upon your requirement and you can discuss about the vidi and vidhan prior to the Puja being booked."
                      }
                    },
                    {
                      "@type":"Question",
                      "name":"How can the customers pay? Through cash or Wallet or Netbanking?",
                      "acceptedAnswer":
                      {
                        "@type":"Answer",
                        "text":"Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)"
                      }
                    }
                  ]
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "ImageObject",
                  "author": "NamasteGod",
                  "name": "${data.allMarkdownRemark.edges[0].node.frontmatter.title}",
                  "contentUrl": "https://www.namastegod.com${data.allFile.nodes[0].childImageSharp.fluid.src}",
                  "contentLocation": "Bangalore, India"
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "Product",
                  "name": "Tamil Vadhyar in Bangalore",
                  "image": "https://www.namastegod.com${data.allFile.nodes[0].childImageSharp.fluid.src}",
                  "description": "Looking for Tamil Vadhyar in Bangalore, India? We provide the best Tamil Vadhyars in Bangalore, Tamil Priest in Bangalore, Iyer Vadhyar in Bangalore, Tamil Brahmin Priests in Bangalore & Tamil Pandits in Bangalore for Marriage, Grihapravesham and others pujas.",
                  "sku": "0000000003",
                  "mpn": "000003",
                  "brand": {
                    "@type": "Brand",
                    "name": "NamasteGod"
                  },
                  "review": {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "5",
                      "bestRating": "5"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Rekha Anoop"
                    }
                  },
                  "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "4.8",
                    "reviewCount": "101"
                  },
                  "offers": {
                    "@type": "Offer",
                    "url": "https://www.namastegod.com/findonline/hindu/tamil-vadhyars-in-bangalore-india-vedic-tamil-priests/",
                    "priceCurrency": "INR",
                    "price": "3100",
                    "priceValidUntil": "2020-12-31",
                    "itemCondition": "https://schema.org/UsedCondition",
                    "availability": "https://schema.org/InStock",
                    "seller": {
                      "@type": "Organization",
                      "name": "NamasteGod"
                    }
                  }
                }
            `}</script>
            <script type="application/ld+json">{`
                {
                  "@context": "http://schema.org",
                  "@type": "LocalBusiness",
                  "name": "Tamil Vadhyar in Bangalore",
                  "telephone": "(+91) 81972 49957",
                  "priceRange": "₹₹₹",
                  "url": "https://www.namastegod.com/findonline/hindu/tamil-vadhyars-in-bangalore-india-vedic-tamil-priests/",
                  "image": "https://www.namastegod.com${data.allFile.nodes[0].childImageSharp.fluid.src}",
                  "address":
                  {
                    "@type": "PostalAddress",
                    "addressLocality": "Bangalore",
                    "addressRegion": "KA",
                    "addressCountry": "IN",
                    "postalCode": "560078",
                    "streetAddress": "#142/1, 17th Cross, 18th Main, JP Nagar 5th Phase"
                   }
                }
            `}</script>
        </Helmet>
        <div id="main" className="pandit-listing">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1 className="desktop">Tamil Vadhyars in Bangalore (15+yrs Exp):<br/>Book Tamil Priests & Iyer Brahmins in Bangalore</h1>
                        <h1 className="mobile">Tamil Vadhyars in Bangalore<br/>(15+yrs Exp):Book Tamil Priests <br/>& Iyer Brahmins in Bangalore</h1>
                    </header>
                    <a href="https://g.co/kgs/MciV3q">
                        <span className="reviews">
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <i className="fa fa-star" aria-hidden="true"></i>
                            <span>Reviews</span>
                        </span>
                    </a>
                    <p>Looking for Tamil Vadhyar in Bangalore, India? We provide the best Tamil Vadhyars in Bangalore, Tamil <a href='https://en.wikipedia.org/wiki/Purohit' className='no-highlight'>Priest</a> in Bangalore, Iyer Vadhyar in Bangalore, Tamil Brahmin Priests in Bangalore & Tamil Pandits in Bangalore for Marriage, Grihapravesham and others pujas.</p>
                    <Link className="button special" to="#contact">Contact Us</Link>
                    <Accordion
          id="1"
          question=" more"
          answer="<h2>Top 5 Tamil Vadhyars, Iyer Vadhyar & Tamil Brahmin Priests in Bangalore</h2>
          <table>
            <tbody>
                <tr>
                    <th>Name</th><th>Region</th>
                    <th>Yrs of Exp</th><th>Pujas Done</th>
                </tr>
                <tr>
                    <td>Tamil Iyer Vadhyar Jayarama Sharma</td>
                    <td>TamilNadu</td>
                    <td>12+</td>
                    <td>3000+</td>
                </tr>
                <tr>
                    <td>Tamil Iyer Vadhyar Bharat Kumar</td><td>TamilNadu</td>
                    <td>12+</td><td>5000+</td>
                </tr>
                <tr>
                    <td>Tamil Iyer Vadhyar Nanjunda Shastri</td>
                    <td>TamilNadu</td><td>30+</td>
                    <td>5000+</td>
                </tr>
                <tr>
                    <td>Tamil Iyer Vadhyar Shiju Panicker</td>
                    <td>TamilNadu</td>
                    <td>10+</td>
                    <td>2000+</td>
                </tr>
                <tr>
                    <td>Tamil Iyer Vadhyar Jagadeesh</td>
                    <td>TamilNadu</td>
                    <td>10+</td>
                    <td>2000+</td>
                </tr>
            </tbody>
        </table>
        <p>Tamil Vadhyars & Iyer Vadhyar in Bangalore sector is considered to be quite popular due to their specialized Tamil puja traditions and vidanam as per the Tamil Brahmin traditions followed by the priests in Bangalore. These Tamil Vadhyars have moved to Bangalore from their respective native hometowns in TamilNadu after successful completion of their Brahmin Pandit degrees. Tamil Vadhyars & Iyer Vadhyars who have come to Bangalore had also underwent several trainings in Puja Vidi, Vidanam from esteemed Gurujis. These Tamil Brahmin Priests had been settled in Bangalore for more than half a decade and had traveled even to the farthest locations in Bangalore.Call Us Now to Book a Tamil Pandit.</p>
<h3>Frequently Asked Questions</h3>
<ol>
<li class='bold'>Should the customer arrange for Puja Samagri or would the Panditji arrange?
<span>Customers have the flexibility to book the Puja along with Samagri or book only Panditji for the Puja.</span></li>
<li class='bold'>Do multiple Pandits call the customer at once upon enquiry, causing them inconvenience?
<span>No. Namaste God values your time and comfort, our team after understanding your requirements arranges a qualified Panditji for the call. In case the customer not satisfied with the Panditji, our team will connect you with another Panditji.</span></li>
<li class='bold'>Will the Panditji help the customer in choosing good muhurth for ritual?
<span>Yes, our Panditji upon connecting with the customer, will refer to the Panchang and provide an appropriate date and time for the ritual to be performed.</span></li>
<li class='bold'>Would the Puja be performed as per the Tamil traditions?
<span>Namaste God provides a wide variety of qualified and experienced panditjis who are hailing from Tamilnadu. Our team would connect you with the appropriate Panditji upon your requirement and you can discuss about the vidi and vidhan prior to the Puja being booked.</span></li>
<li class='bold'>How can the customers pay? Through cash or Wallet or Netbanking?
<span>Customers can pay through Cash or Net Banking or UPI Payments (PayTM, GooglePay) or e-Wallet (PayTM)</span></li>
</ol>"
        />
                </div>
            </section>
            <section id="two" className="spotlights">
                {data.allFile.nodes.map((value, index) => {
                    return <section>
                        <div className="image">
                            <img src={value.childImageSharp.fluid.src} alt={data.allMarkdownRemark.edges[index].node.frontmatter.title} title={data.allMarkdownRemark.edges[index].node.frontmatter.title}/>
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{data.allMarkdownRemark.edges[index].node.frontmatter.title_tamil}</h3>
                                </header>
                                <div className="details">
                                    <span className="label">Years of Experience: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.experience}</span><br/>
                                    <span className="label">Completed his Degree: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.degree}</span><br/>
                                    <span className="label">Well Versed in Puja Traditions as per Regions: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.regions_tamil}</span><br/>
                                    <span className="label">In Bangalore since: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.bangalore}</span><br/>
                                    <span className="label">Specialized In: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.specialized}</span><br/>
                                    <span className="label">Puja’s Performed: </span><span className="value">{data.allMarkdownRemark.edges[index].node.frontmatter.pujas}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                })}
            </section>
        </div>

    </Layout>
}

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___order_tamil}) {
    edges {
      node {
        frontmatter {
          order_tamil
          title
          title_tamil
          experience
          degree
          regions_tamil
          bangalore
          specialized
          pujas
        }
      }
    }
  }
  allFile(filter: {name: {in: ["Pandit Ta1Jayarama Sharma", "Pandit Ta2Bharat Kumar", "Pandit Ta3Nanjunda", "Pandit Ta4Shiju Panekar", "Pandit Ta5Jagadeesh"]}} , sort: {fields: name}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
}`

export default TamilAll